import { Link } from "gatsby"
import * as React from "react"
import BasicPage from "../components/commons/basic-page"
import Layout from "../components/layout/es"

const AvisoLegalPage = (props, Content) => {

  return (
    <Layout robots="block" pageId="legal" location={props.location} metaTitle="Aviso legal | Crequs">
        <BasicPage 
            title="Aviso legal">
            <p>Con el fin de dar cumplimiento al art&iacute;culo 10 de la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico, informamos a los usuarios de nuestros datos:</p>
            <p><strong>Denominaci&oacute;n Social:</strong>&nbsp;Paolo Bergamelli<br /><strong>Domicilio Social:</strong>&nbsp;Calle Rollo 5, 28005, Madrid, Espa&ntilde;a<br /><strong>NIF:</strong>&nbsp;Y2729791G<br /><strong>Tel&eacute;fono:</strong>&nbsp;685337685<br /><strong>E-Mail:</strong>&nbsp;info@crequs.com<br /><strong>Sitio Web:</strong>&nbsp;crequs.com</p>
            <p>&nbsp;</p>
            <h2>1- OBJETO</h2>
            <p><strong>Paolo Bergamelli</strong>&nbsp;(en adelante tambi&eacute;n el prestador) c&oacute;mo responsable del sitio web, pone a disposici&oacute;n de los usuarios el presente documento, que regula el uso del sitio web crequs.com, con el que pretendemos dar cumplimiento a las obligaciones que dispone la Ley 34/2002, de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico (LSSICE o LSSI), as&iacute; como informar a todos los usuarios del sitio web respecto de cu&aacute;les son las condiciones de uso del sitio web.</p>
            <p>A trav&eacute;s de la Web,&nbsp;<strong>Paolo Bergamelli</strong>&nbsp;facilita a los usuarios el acceso y utilizaci&oacute;n de diferentes servicios y contenidos puestos a disposici&oacute;n a trav&eacute;s del web.</p>
            <p>Toda persona que acceda a esta web asume el papel de usuario (en adelante el usuario), e implica la aceptaci&oacute;n total y sin reservas de todas y cada una de las disposiciones incluidas en este aviso legal, as&iacute; como a cualesquiera otras disposiciones legales que fueran de aplicaci&oacute;n.</p>
            <p>Como usuarios, tienen que leer atentamente este Aviso Legal en cualquier de las ocasiones que entren a la web, pues &eacute;sta puede sufrir modificaciones ya que el prestador se reserva el derecho a modificar cualquier tipo de informaci&oacute;n que pudiera aparecer en la web, sin que exista la obligaci&oacute;n de pre-avisar o poner en conocimiento de los usuarios estas obligaciones, siendo suficientes la publicaci&oacute;n en el sitio web del prestador.</p>
            <p>&nbsp;</p>
            <h2>2- CONDICIONES DE ACCESO Y USO DE LA WEB</h2>
            <p><strong>2.1. Car&aacute;cter gratuito del acceso y uso de la web</strong></p>
            <p>La prestaci&oacute;n de los servicios por parte del prestador tiene car&aacute;cter gratuito para todos los Usuarios. No obstante, algunos de los servicios suministrados por el prestador a trav&eacute;s de la Web est&aacute;n sujetas al pago de un precio determinado a las condiciones generales de contrataci&oacute;n.</p>
            <p><strong>2.2. Registro de Usuario</strong></p>
            <p>Con car&aacute;cter general la prestaci&oacute;n de los Servicios no exige la previa suscripci&oacute;n o registro de los Usuarios.&nbsp;</p>
            <p><strong>2.3. Veracidad de la informaci&oacute;n</strong></p>
            <p>Toda la informaci&oacute;n que facilita el Usuario tiene que ser veraz. A estos efectos, el Usuario garantiza la autenticidad de los datos comunicados a trav&eacute;s de los formularios para la suscripci&oacute;n de los Servicios. Ser&aacute; responsabilidad del Usuario mantener toda la informaci&oacute;n facilitada al prestador&nbsp;permanentemente actualizada de forma que responda, en cada momento, a su situaci&oacute;n real. En todo caso, el Usuario ser&aacute; el &uacute;nico responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause al prestador o a terceros.</p>
            <p><strong>2.4. Menores de edad</strong></p>
            <p>Para el uso de los servicios, los menores de edad tienen que obtener siempre previamente el consentimiento de los padres, tutores o representantes legales, responsables &uacute;ltimos de todos los actos realizados por los menores a su cargo. La responsabilidad en la determinaci&oacute;n de contenidos concretos a los cuales acceden los menores corresponde a aquellos, es por eso que si acceden a contenidos no apropiados por Internet, se tendr&aacute;n que establecer en sus ordenadores mecanismos, en particular programas inform&aacute;ticos, filtros y bloqueos, que permitan limitar los contenidos disponibles y, a pesar de que no sean infalibles, son de especial utilidad para controlar y restringir los materiales a los que pueden acceder los menores.</p>
            <p><strong>2.5. Obligaci&oacute;n de hacer un uso correcto de la Web</strong></p>
            <p>El Usuario se compromete a utilizar la Web de conformidad a la Ley y al presente Aviso Legal, as&iacute; como a la moral y a buenas costumbres. A tal efecto, el Usuario se abstendr&aacute; de utilizar la p&aacute;gina con finalidades il&iacute;citas o prohibidas, lesivas de derechos e intereses de terceros, o que de cualquier forma puedan da&ntilde;ar, inutilizar, sobrecargar, deteriorar o impedir la normal utilizaci&oacute;n de equipos inform&aacute;ticos o documentos, archivos y toda clase de contenidos almacenados en cualquier equipo inform&aacute;tico del prestador.</p>
            <p>En particular, y a t&iacute;tulo indicativo pero no exhaustivo, el Usuario se compromete a no transmitir, difundir o poner a disposici&oacute;n de terceros informaciones, datos, contenidos, mensajes, gr&aacute;ficos, dibujos, archivos de sonido o imagen, fotograf&iacute;as, grabaciones, software y, en general, cualquier clase de material que:</p>
            <p>(a) sea contraria, desprecie o atente contra los derechos fundamentales y las libertades p&uacute;blicas reconocidas constitucionalmente, en tratados internacionales y otras normas vigentes;<br />(b) induzca, incite o promueva actuaciones delictivas, denigrantes, difamatorias, violentas o, en general, contrarias a la ley, a la moral y al orden p&uacute;blico;<br />(c) induzca, incite o promueva actuaciones, actitudes o pensamientos discriminatorios por raz&oacute;n de sexo, raza, religi&oacute;n, creencias, edad o condici&oacute;n;<br />(d) sea contrario al derecho al honor, a la intimidad personal o familiar o a la propia imagen de las personas;<br />(e) de cualquier manera perjudique la credibilidad del prestador o de terceros; y<br />(f) constituya publicidad il&iacute;cita, enga&ntilde;osa o desleal.</p>
            <h2>3- EXCLUSI&Oacute;N DE GARANT&Iacute;AS Y RESPONSABILIDAD</h2>
            <p>El prestador se exime de cualquier tipo de responsabilidad derivada de la informaci&oacute;n publicada en nuestro sitio web, siempre que esta informaci&oacute;n haya sido manipulada o introducida por un tercero ajeno.</p>
            <p>Esta web ha sido revisada y probada para que funcione correctamente. En principio, se puede garantizar el correcto funcionamiento los 365 d&iacute;as del a&ntilde;o, 24 horas al d&iacute;a. Sin embargo, el prestador no descarta la posibilidad que existan determinados errores de programaci&oacute;n, o que sucedan causas de fuerza mayor, cat&aacute;strofes naturales, huelgas, o circunstancias parecidas que hagan imposible el acceso a la p&aacute;gina web.</p>
            <p>El prestador&nbsp;no otorga ninguna garant&iacute;a ni se hace responsable, en ning&uacute;n caso, de los da&ntilde;os y perjuicios de cualquier naturaleza que puedan salir a colaci&oacute;n de la falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web o de sus servicios y contenidos; de la existencia de virus, programas maliciosos o lesivos en los contenidos; del uso il&iacute;cito, negligente, fraudulento o contrario a este Aviso Legal y condiciones de uso; o de La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros y ademanes a disposici&oacute;n de los usuarios en el sitio web.</p>
            <p>El prestador no se hace responsable bajo ning&uacute;n concepto de los da&ntilde;os que puedan dimanar del uso ilegal o indebido de la presente p&aacute;gina web.</p>
            <h2>4- COOKIES</h2>
            <p>El sitio web del prestador puede utilizar cookies (peque&ntilde;os archivos de informaci&oacute;n que el servidor env&iacute;a al ordenador de quien accede en la p&aacute;gina) para llevar a cabo determinadas funciones que son consideradas imprescindibles para el correcto funcionamiento y visualizaci&oacute;n del lugar. Las cookies utilizadas tienen, en todo caso, car&aacute;cter temporal, con la &uacute;nica finalidad de hacer m&aacute;s eficaz la navegaci&oacute;n, y desaparecen al acabar la sesi&oacute;n del usuario.</p>
            <p>En ning&uacute;n caso se utilizar&aacute;n las cookies para recoger informaci&oacute;n de car&aacute;cter personal. Para m&aacute;s informaci&oacute;n, ver nuestra&nbsp;<Link to="/politica-de-cookies" rel="nofollow">Pol&iacute;tica de Cookies</Link>.</p>
            <h2>6 &ndash; PROTECCI&Oacute;N DE DATOS PERSONALES</h2>
            <p>El prestador est&aacute; profundamente comprometido con el cumplimiento de la normativa de protecci&oacute;n de datos personales y garantiza el cumplimiento &iacute;ntegro de las obligaciones dispuestas, as&iacute; como la implementaci&oacute;n de las medidas de seguridad dispuestas al Reglamento Europeo de Protecci&oacute;n de datos y la normativa espa&ntilde;ola de protecci&oacute;n de datos. Para m&aacute;s informaci&oacute;n, ver nuestra&nbsp;<Link to="/politica-de-privacidad" rel="nofollow">Pol&iacute;tica de Privacidad.</Link></p>
            <h2>7 &ndash; PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
            <p>El sitio web, incluyendo a t&iacute;tulo enunciativo pero no limitativo la programaci&oacute;n, edici&oacute;n, compilaci&oacute;n y otros elementos necesarios para su funcionamiento, los dise&ntilde;os, logotipos, textos, fotograf&iacute;as y/o gr&aacute;ficos son propiedad del prestador o, en caso de que fuera necesario, dispone de la licencia o autorizaci&oacute;n expresa por parte de los autores. Todos los contenidos del sitio web se encuentran debidamente protegidos por la normativa de propiedad intelectual e industrial.</p>
            <p>Independientemente de la finalidad por la que fueran destinados, la reproducci&oacute;n total o parcial, uso, distribuci&oacute;n y comunicaci&oacute;n p&uacute;blica requieren de la autorizaci&oacute;n escrita previa por parte del prestador. Cualquier uso no autorizado previamente por parte del prestador ser&aacute; considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.</p>
            <p>Los dise&ntilde;os, logotipos, textos y/o gr&aacute;ficos ajenos al prestador y que puedan aparecer en el sitio web, pertenecen a sus respectivos propietarios, siendo ellos mismos los responsables de cualquier posible controversia que pudiera acontecer respecto a los mismos. En todo caso, el prestador cuenta con la autorizaci&oacute;n expresa y previa por parte de los mismos.</p>
            <p>El prestador reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual, no implicando la menci&oacute;n o aparici&oacute;n en el sitio web, de la existencia de derechos o ninguna responsabilidad del prestador sobre los mismos, como tampoco respaldo, patrocinio o recomendaci&oacute;n por parte del mismo.</p>
            <p>Para realizar cualquier tipo de observaci&oacute;n respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, as&iacute; como sobre cualquiera de los contenidos del sitio web, puede hacerlo a trav&eacute;s del correo electr&oacute;nico arriba rese&ntilde;ado.</p>
            <h2>8 &ndash; LEY APLICABLE Y JURISDICCI&Oacute;N</h2>
            <p>Para la resoluci&oacute;n de las controversias o cuestiones relacionadas con la presente p&aacute;gina web o de las actividades en esta desarrolladas, ser&aacute; de aplicaci&oacute;n la legislaci&oacute;n espa&ntilde;ola, a la que se someten expresamente las partes, siendo competentes por la resoluci&oacute;n de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales de Madrid.</p>     
        </BasicPage>
    </Layout>
  )
}

export default AvisoLegalPage


